/* Global styles */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

body {
  background-color: #f8f9fa;
  font-family: 'Nunito Sans', sans-serif !important;
}

.mb-3 {
  margin-bottom: 1rem;
}

.semi-bold {
  font-weight: bold;
}

body a {
  color: #00ADEF;
  text-decoration: none;
}

body a:hover {
  color: #0182ca;
}

.pull-right {
  float: right;
}

.centered {
  margin: 0 auto;
}

.btn-primary {
  background-color: #00ADEF !important;
}

svg.text-primary,
.text-primary {
  color: #00ADEF !important;
}

.btn-primary:hover {
  background-color: #0596cf !important;
}

.padding-15 {
  padding: 15px;
}

.text-left {
  text-align: left !important;
}

.btn-default {
  border: 1px solid #d7d7d7 !important;
  color: #666666;
}

.group-toggle {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  color: #666666 !important;
  cursor: pointer;
  background: none;
  border: none !important;
  border-left: 1px solid #dee2e6 !important;
}

div.group-toggle button {
  color: #666666 !important;
  border: none !important;
  height: 35px;
  border-radius: 0px;
}

div.group-toggle button.btn-danger {
  color: white !important;
}

.mr-1 {
  margin-right: 1em !important;
}

.mr-02 {
  margin-right: .2em !important;
}

.mr-05 {
  margin-right: .5em;
}

.ml-5 {
  margin-left: .5em;
}

.ml-auto {
  margin-left: auto !important;
}

.btn-block {
  width: 100% !important;
}

.accordion {
  position: relative;
  z-index: 1 !important;
}

.my-select {
  position: relative;
  z-index: 10 !important;
}

.btn-orange {
  color: white !important;
  background-color: #f18614 !important;
}

.btn-yellow {
  color: white !important;
  background-color: #fcd015 !important;
}

.btn-blue {
  color: white !important;
  background-color: #0281de !important;
}

.btn-red {
  color: white !important;
  background-color: #d0000d !important;
}

.btn-green {
  color: white !important;
  background-color: #01901a !important;
}

.btn-black {
  color: white !important;
  background-color: #000000 !important;
}

.btn-yellow:hover,
.btn-blue:hover,
.btn-red:hover,
.btn-green:hover,
.btn-black:hover,
.btn-orange:hover {
  opacity: 0.8 !important;
}

.btn-yellow.active,
.btn-blue.active,
.btn-red.active,
.btn-green.active,
.btn-black.active,
.btn-orange.active {
  opacity: 0.5 !important;
}

/* Padding Utilities */

/* Optional: Specific size padding/margin based on Bootstrap breakpoints */
@media (min-width: 992px) {

  /* lg */
  .no-padding-lg {
    padding: 0 !important;
  }

  .no-margin-lg {
    margin: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* md */
  .no-padding-md {
    padding: 0 !important;
  }

  .no-margin-md {
    margin: 0 !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {

  /* sm */
  .no-padding-sm {
    padding: 0 !important;
  }

  .no-margin-sm {
    margin: 0 !important;
  }
}

@media (max-width: 575px) {

  /* xs */
  .no-padding-xs,
  .no-padding-xs.p-4,
  .no-padding-xs.p-3 {
    padding: 0 !important;
  }

  .no-margin-xs {
    margin: 0 !important;
  }

  .mt-xs-3 {
    margin-top: 1em !important;
  }
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Card styles */
.card {
  border: none;
  overflow: hidden;
}

.card img {
  height: 200px;
  object-fit: cover;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
}

.card-text {
  font-size: 14px;
  color: #6c757d;
}

.card-text span {
  display: block;
}

.card-text span:first-child {
  margin-bottom: 5px;
}


.box-passeios .card-title {
  margin: 0;
  text-align: left;
  font-size: 14px;
  font-weight: bolder;
  height: 36px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.box-passeios img {
  height: 365px;
  cursor: pointer;
}

.box-passeios img:hover {
  -webkit-filter: brightness(55%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.box-passeios .price {
  text-align: right;
  color: white;
}

.box-passeios .rating-box {
  color: white;
  padding-top: 4px;
}

.box-passeios .rating-box svg {
  color: white;
}

.box-passeios .btn-primary {
  height: 30px;
  line-height: 2px;
  border-radius: 10px;
  background-color: #00ADEF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.box-passeios .btn-primary:hover {
  background-color: #0580b1;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}


.margin-bottom-25 {
  margin-bottom: 25px;
}

.box-passeios .card-body-overlay {
  background-color: rgba(36, 119, 165, 0.8);
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: white;
  min-height: 115px;
}


.row.g-2 {
  row-gap: 8px !important;
}

.ad {
  display: flex;
  justify-content: center;
}

.ad-image {
  max-width: 100%;
  height: auto;
  max-height: 100px;
}


/* Navigation styles */
.nav-tabs .nav-link {
  font-size: 14px;
  font-weight: bold;
}

.nav-pills .nav-link {
  margin-right: 10px;
}

.nav-pills .nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.nav-pills .nav-link {
  color: #007bff;
} 
.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.nav-tabs .nav-link {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .nav-tabs .nav-link {
      font-size: 12px;
      padding: 8px 5px;
  }
}

.tabs-category .active {
  border: 0px !important;
}

.tabs-category {
  border: 0px !important;
}

.tabs-category .nav-item {
  border: 0px !important;
  border-bottom: 1px solid #D9D9D9 !important;
  padding-right: 30px;
}

.tabs-category .nav-item a {
  font-size: 20px;
  color: #666666;
  font-weight: 400;
}

.tabs-category .nav-item a.active {
  font-size: 20px;
  color: #1EB5EF;
  border-bottom: 1px solid #1EB5EF !important;
}

.box-categories .card-body-overlay {
  background-color: rgba(36, 119, 165, 0.8);
  position: absolute;
  bottom: 0;
  width: calc(100% - 5px);
  padding: 10px;
  box-sizing: border-box;
  color: white;
}

.box-categories .card-title {
  margin: 0;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}
.box-categories img:hover {
  -webkit-filter: brightness(55%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.box-categories img {
  height: 150px !important;
  padding-right: 5px;
  cursor: pointer;
}